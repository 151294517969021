var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-margin relative pb-2 _question-panel" },
    [
      _c(
        "div",
        { staticClass: "__difficulty-text" },
        [
          _c("difficulty-indicator", {
            attrs: { difficulty: _vm._.get(_vm.item, "data.difficulty", "") },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "flex mb-4" }, [
        _c(
          "div",
          {
            staticClass: "bg-grid-color-secondary mr-2",
            staticStyle: { width: "100%" },
          },
          [
            _c("TitleComponent", {
              staticClass: "ml-2 mt-2 w-full",
              attrs: {
                placeholder: "Título",
                tag: "h5",
                item: _vm.item,
                "update-title": false,
                "editor-type": "balloon",
              },
              on: { "title-TITLE_UPDATED": _vm.updateTitle },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "flex mb-4" }, [
        _c(
          "div",
          {
            staticClass: "bg-grid-color-secondary mr-2",
            staticStyle: { width: "100%" },
          },
          [
            _c("ComplexEditor", {
              attrs: {
                questionnaire_id: _vm.parent_id,
                editor_data: _vm.editorData,
                placeholder: "Texto de apoio...",
                autoOpen: _vm.autoOpenEditor,
                dontClose: _vm.dontCloseEditor,
              },
              on: {
                "update:editor_data": function ($event) {
                  _vm.editorData = $event
                },
                blur: _vm.onBlur,
                destroy: _vm.onDestroy,
                "data-changed": _vm.onDataChange,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "vs-popup",
        {
          directives: [
            {
              name: "esc",
              rawName: "v-esc",
              value: _vm.closeEssayLimitsPopup,
              expression: "closeEssayLimitsPopup",
            },
          ],
          staticStyle: { "overflow-y": "auto", "z-index": "54000" },
          attrs: {
            title: "Configurar Limites",
            active: _vm.showEssayLimitsPopup,
          },
          on: {
            "update:active": function ($event) {
              _vm.showEssayLimitsPopup = $event
            },
          },
        },
        [
          _c("div", { staticClass: "flex flex-wrap items-center" }, [
            _c("div", { staticClass: "flex mb-4 w-full" }, [
              _c(
                "div",
                {
                  staticClass: "bg-grid-color-secondary mr-2",
                  staticStyle: { width: "100%" },
                },
                [
                  _c("div", { staticClass: "grid grid-cols-3" }, [
                    _c("div", { staticClass: "flex items-center" }, [
                      _vm._v(" Qtd. Palavras "),
                    ]),
                    _c(
                      "div",
                      [
                        _c("vs-input", {
                          staticClass: "w-32",
                          attrs: { label: "Mínimo", name: "words_min" },
                          on: {
                            change: function ($event) {
                              return _vm.updateLimits()
                            },
                          },
                          model: {
                            value: _vm.limits.words.min,
                            callback: function ($$v) {
                              _vm.$set(_vm.limits.words, "min", $$v)
                            },
                            expression: "limits.words.min",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("vs-input", {
                          staticClass: "w-32",
                          attrs: { label: "Máximo", name: "words_max" },
                          on: {
                            change: function ($event) {
                              return _vm.updateLimits()
                            },
                          },
                          model: {
                            value: _vm.limits.words.max,
                            callback: function ($$v) {
                              _vm.$set(_vm.limits.words, "max", $$v)
                            },
                            expression: "limits.words.max",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "grid grid-cols-3 mt-2" }, [
                    _c("div", { staticClass: "flex items-center" }, [
                      _vm._v(" Qtd. Frases "),
                    ]),
                    _c(
                      "div",
                      [
                        _c("vs-input", {
                          staticClass: "w-32",
                          attrs: { label: "Mínimo", name: "phrases_min" },
                          on: {
                            change: function ($event) {
                              return _vm.updateLimits()
                            },
                          },
                          model: {
                            value: _vm.limits.phrases.min,
                            callback: function ($$v) {
                              _vm.$set(_vm.limits.phrases, "min", $$v)
                            },
                            expression: "limits.phrases.min",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("vs-input", {
                          staticClass: "w-32",
                          attrs: { label: "Máximo", name: "phrases_max" },
                          on: {
                            change: function ($event) {
                              return _vm.updateLimits()
                            },
                          },
                          model: {
                            value: _vm.limits.phrases.max,
                            callback: function ($$v) {
                              _vm.$set(_vm.limits.phrases, "max", $$v)
                            },
                            expression: "limits.phrases.max",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "grid grid-cols-3 mt-2" }, [
                    _c("div", { staticClass: "flex items-center" }, [
                      _vm._v(" Qtd. Parágrafos "),
                    ]),
                    _c(
                      "div",
                      [
                        _c("vs-input", {
                          staticClass: "w-32",
                          attrs: { label: "Mínimo", name: "paragraphs_min" },
                          on: {
                            change: function ($event) {
                              return _vm.updateLimits()
                            },
                          },
                          model: {
                            value: _vm.limits.paragraphs.min,
                            callback: function ($$v) {
                              _vm.$set(_vm.limits.paragraphs, "min", $$v)
                            },
                            expression: "limits.paragraphs.min",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("vs-input", {
                          staticClass: "w-32",
                          attrs: { label: "Máximo", name: "paragraphs_max" },
                          on: {
                            change: function ($event) {
                              return _vm.updateLimits()
                            },
                          },
                          model: {
                            value: _vm.limits.paragraphs.max,
                            callback: function ($$v) {
                              _vm.$set(_vm.limits.paragraphs, "max", $$v)
                            },
                            expression: "limits.paragraphs.max",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm.questionnaireMode
        ? _c("Footer", {
            attrs: {
              showInvalidateButton: _vm.showInvalidateButton,
              item: _vm.item,
              parent_id: _vm.parent_id,
              cheetEnabled: _vm.cheetEnabled,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }