
<template>
  <div class="panel-margin relative pb-2 _question-panel">
    <div class="__difficulty-text">
      <difficulty-indicator :difficulty="_.get(item, 'data.difficulty', '')"/>
    </div>

    <div class="flex mb-4">

      <div class="bg-grid-color-secondary mr-2" style="width: 100%">

        <TitleComponent
          placeholder="Título"
          tag="h5"
          class="ml-2 mt-2 w-full"
          :item="item"
          :update-title="false"
          editor-type="balloon"
          @title-TITLE_UPDATED="updateTitle"
        ></TitleComponent>

      </div>

    </div>

    <div class="flex mb-4">

      <div class="bg-grid-color-secondary mr-2" style="width: 100%">
        <ComplexEditor
          :questionnaire_id="parent_id"
          v-bind:editor_data.sync="editorData"
          v-on:blur="onBlur"
          v-on:destroy="onDestroy"
          @data-changed="onDataChange"
          placeholder="Texto de apoio..."
          :autoOpen="autoOpenEditor"
          :dontClose="dontCloseEditor"
        ></ComplexEditor>
      </div>
    </div>

    <vs-popup
      title="Configurar Limites"
      :active.sync="showEssayLimitsPopup"
      style="overflow-y: auto; z-index: 54000;"
      v-esc="closeEssayLimitsPopup"
    >
      <div class="flex flex-wrap items-center">

        <div class="flex mb-4 w-full">

          <div class="bg-grid-color-secondary mr-2" style="width: 100%">

            <div class="grid grid-cols-3">
              <div class="flex items-center">
                Qtd. Palavras
              </div>
              <div>
                <vs-input
                  label="Mínimo"
                  v-model="limits.words.min"
                  name="words_min"
                  @change="updateLimits()"
                  class="w-32"
                />
              </div>
              <div>
                <vs-input
                  label="Máximo"
                  v-model="limits.words.max"
                  name="words_max"
                  @change="updateLimits()"
                  class="w-32"
                />
              </div>
            </div>

            <div class="grid grid-cols-3 mt-2">
              <div class="flex items-center">
                Qtd. Frases
              </div>
              <div>
                <vs-input
                  label="Mínimo"
                  v-model="limits.phrases.min"
                  name="phrases_min"
                  @change="updateLimits()"
                  class="w-32"
                />
              </div>
              <div>
                <vs-input
                  label="Máximo"
                  v-model="limits.phrases.max"
                  name="phrases_max"
                  @change="updateLimits()"
                  class="w-32"
                />
              </div>
            </div>

            <div class="grid grid-cols-3 mt-2">
              <div class="flex items-center">
                Qtd. Parágrafos
              </div>
              <div>
                <vs-input
                  label="Mínimo"
                  v-model="limits.paragraphs.min"
                  name="paragraphs_min"
                  @change="updateLimits()"
                  class="w-32"
                />
              </div>
              <div>
                <vs-input
                  label="Máximo"
                  v-model="limits.paragraphs.max"
                  name="paragraphs_max"
                  @change="updateLimits()"
                  class="w-32"
                />
              </div>
            </div>

            <!-- <div class="grid grid-cols-3 mt-2">
              <div class="flex items-center">
                Qtd. Linhas
              </div>
              <div>
                <vs-input
                  label="Mínimo"
                  v-model="limits.lines.min"
                  name="lines_min"
                  @change="updateLimits()"
                  class="w-32"
                />
              </div>
              <div>
                <vs-input
                  label="Máximo"
                  v-model="limits.lines.max"
                  name="lines_max"
                  @change="updateLimits()"
                  class="w-32"
                />
              </div>
            </div> -->

          </div>

        </div>

      </div>

    </vs-popup>

    <Footer :showInvalidateButton="showInvalidateButton" v-if="questionnaireMode" :item="item" :parent_id="parent_id" :cheetEnabled="cheetEnabled"></Footer>
  </div>
</template>

<script>
import ComplexEditor from '../ComplexEditor'
import Footer from '../Footer'
import DifficultyIndicator from '@/components/DifficultyIndicator.vue'
import TitleComponent from './title/TitleComponent'

export default {
  components: {
    ComplexEditor,
    Footer,
    DifficultyIndicator,
    TitleComponent
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    parent_id: {
      type: Number,
      default: null,
    },
    mode: {
      type: String,
      default: 'questionnaire'
    },
    autoOpenEditor: {
      type: Boolean,
      default: false
    },
    dontCloseEditor: {
      type: Boolean,
      default: false
    },
    showInvalidateButton: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    cheetEnabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      editorData: this.item !== null ? this.item.data.description : '',
      title: '',
      limits: {
        characteres: {
          min: null,
          max: null
        },
        words: {
          min: null,
          max: null
        },
        phrases: {
          min: null,
          max: null
        },
        paragraphs: {
          min: null,
          max: null
        }
      },
      showEssayLimitsPopup: false
    }
  },
  computed: {
    questionnaireMode() {
      return this.mode === 'questionnaire'
    },
    formMode() {
      return this.mode === 'form'
    }
  },
  methods: {
    updateTitle(item, type, value) {
      if (this.item.id === item.id) {
        const data = this._.cloneDeep(item.data)
        data.title = value
        // this.editorData = value
        this.item.data = data
        this.$parent.$emit('item-ITEM_UPDATED', this.item)
      }
    },
    updateLimits() {
      this.item.data.limits = this.limits
      this.$parent.$emit('item-ITEM_UPDATED', this.item)
      console.log('updateLimits...')
    },
    onBlur() {
      this.updateItem()
    },
    updateItem() {
      this.item.data.description = this.editorData
      this.$parent.$emit('item-ITEM_UPDATED', this.item)
    },
    onDataChange() {
      this.updateItem()
    },
    onEditorDestroy() {
      this.item.data.description = this.html2text(this.editorData)
    },
    showEssayLimits() {
      this.showEssayLimitsPopup = !this.showEssayLimitsPopup
    },
    closeEssayLimitsPopup() {
      this.showEssayLimitsPopup = false
    }
  },
  mounted() {

    this.$emit('questionRendered')

    if (this.item.data.limits) {
      this.limits = this.item.data.limits
    }

    this.$on('item-TITLE_UPDATED', (item, type, value) => {
      console.log('item-TITLE_UPDATED...', item, value)
      if (this.item.id === item.id) {
        const data = this._.cloneDeep(event.data)
        data.description = value
        this.editorData = value
        this.$parent.$emit('item-ITEM_UPDATED', this.item)
      }
    })

    this.$on('footer-DELETE_ITEM', (item) => {
      this.$parent.$emit('item-DELETE_ITEM', item)
    })

    this.$on('footer-INVALIDATE_ITEM', (item) => {
      this.$parent.$emit('item-INVALIDATE_ITEM', item)
    })

    this.$on('footer-REVALIDATE_ITEM', (data) => {
      this.$parent.$emit('item-REVALIDATE_ITEM', data)
    })

    this.$on('footer-DUPLICATE_ITEM', (item) => {
      this.$parent.$emit('item-DUPLICATE_ITEM', item)
    })

    this.$on('footer-ITEM_UPDATED', (item) => {
      this.$parent.$emit('item-ITEM_UPDATED', item)
    })
  }
}
</script>

<style>
</style>
